<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        v-if="!editMode"
        :can="canUpdateCountries"
        @click="toggleEditMode"
      >
        Edit
      </vx-button>
      <div v-else>
        <vx-button
          variant="primary"
          :loading="updateCountriesLoading"
          @click="save"
        >
          Save
        </vx-button>
        <vx-button
          class="ml-1"
          variant="danger"
          @click="cancelUpdate"
        >
          Cancel
        </vx-button>
      </div>
    </vx-page-title-actions>
    <vx-table-simple
      :items="items"
      :columns="columns"
      :filters="filters"
      :loading="loading"
      sticky-header-full
    />
  </div>
</template>

<script>
import {
  VxCellPaymentCountryCheckbox,
  VxHeadCellPaymentCountryCheckbox,
  VxTableSimple
} from '@/components/table'
import { paymentSystems, useResource } from '@/services/resources'
import { provide, ref, shallowRef } from '@vue/composition-api'
import usePaymentCountriesStore from '@/views/payments/usePaymentCountriesStore'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import VxButton from '@/components/buttons/VxButton'

export default {
  name: 'PaymentCountries',
  components: {
    VxButton,
    VxPageTitleActions,
    VxTableSimple
  },
  setup () {
    const {
      loading: updateCountriesLoading,
      can: canUpdateCountries,
      callRequest: updateCountriesRequest
    } = useResource(paymentSystems.updateCountries)
    const {
      loading,
      callRequest: getCountriesRequest
    } = useResource(paymentSystems.getCountries)

    const items = shallowRef([])
    const countryIds = []

    const columns = ref([
      { key: 'name', label: 'Country' },
      { key: 'id', label: 'Code' }
    ])

    const filters = [
      { key: 'name', label: 'Country' },
      { key: 'id', label: 'Code' }
    ]

    /**
     * Store changed cells with this structure
     * {
     *   payment_system_id: {
     *     country_id: bool
     *   }
     * }
     */
    const changedItems = {}

    const onChangeCell = (value, rowKey, columnKey, item) => {
      const paymentSystemId = Number(columnKey)
      const countryId = item.id

      changedItems[paymentSystemId][countryId] = value
    }

    const onChangeColumn = (value, columnKey) => {
      const paymentSystemId = Number(columnKey)

      changedItems[paymentSystemId] = countryIds.reduce((res, id) => {
        res[id] = value
        return res
      }, {})
    }

    const clearChanged = () => {
      for (const key in changedItems) {
        changedItems[key] = {}
      }
    }

    const checkboxStore = usePaymentCountriesStore({
      onChangeCell,
      onChangeColumn
    })
    const {
      editMode,
      toggleEditMode,
      setItems,
      setColumn,
      setInitialCheckedCountCell,
      cancel
    } = checkboxStore
    provide('checkboxStore', checkboxStore)

    const getCountries = async () => {
      const [err, res] = await getCountriesRequest()
      if (err) return

      const { data } = res

      setPaymentSystemColumns(data[0].payment_system_countries)
      const formattedData = backToFrontMapper(data)
      setItems(formattedData)
      items.value = formattedData
    }

    const setPaymentSystemColumns = (paymentSystemCountries) => {
      paymentSystemCountries.forEach(({ payment_system }) => {
        const paymentSystemId = payment_system.id.toString()

        setColumn(paymentSystemId)
        changedItems[payment_system.id] = {}

        columns.value.push({
          label: payment_system.name,
          key: paymentSystemId,
          component: VxCellPaymentCountryCheckbox,
          tdAttr: { editMode },
          headComponent: VxHeadCellPaymentCountryCheckbox
        })
      })
    }

    const backToFrontMapper = (countries) => {
      return countries.map(({ name, id, payment_system_countries }) => {
        const row = { name, id }
        countryIds.push(id)

        payment_system_countries.forEach(({ is_active, payment_system_id }) => {
          const paymentSystemId = payment_system_id.toString()

          row[paymentSystemId] = is_active

          if (is_active) {
            setInitialCheckedCountCell(is_active, paymentSystemId)
          }
        })

        return row
      })
    }

    const frontToBackMapper = () => {
      const res = []
      for (const payment_system_id in changedItems) {
        for (const country_id in changedItems[payment_system_id]) {
          res.push({
            payment_system_id: Number(payment_system_id),
            country_id,
            is_active: changedItems[payment_system_id][country_id]
          })
        }
      }
      return res
    }

    const save = async () => {
      const [err] = await updateCountriesRequest({
        data: { payment_countries: frontToBackMapper() }
      })
      if (err) return

      cancelUpdate(false)
    }

    const cancelUpdate = (setInitialData = true) => {
      cancel(setInitialData)
      toggleEditMode()
      clearChanged()
    }

    getCountries()

    return {
      items,
      columns,
      filters,
      loading,
      changedItems,

      editMode,
      toggleEditMode,
      save,
      cancelUpdate,

      updateCountriesLoading,
      canUpdateCountries
    }
  }
}
</script>
