import { computed, reactive, readonly, ref, set } from '@vue/composition-api'
import { cloneDeep, isObject } from 'lodash'

const usePaymentCountriesStore = ({
  onChangeCell = () => {},
  onChangeColumn = () => {}
} = {}) => {
  // START: Edit mode toggle
  const editMode = ref(false)

  const toggleEditMode = () => {
    editMode.value = !editMode.value
  }
  // END

  // START: Items (rows) store
  /**
   * Structure of innerItems:
   * {
   *   countryId: {
   *     paymentMethodId: true|false,
   *     ...
   *     title: string
   *     code: string
   *     id: number
   *   },
   *   ...
   * }
   */
  let initialItems = null
  const innerItems = ref({})

  const itemsCount = computed(() => {
    if (!isObject(innerItems.value)) return 0

    return Object.keys(innerItems.value).length
  })

  const getItemsMappedById = (items) => {
    return items.reduce((res, item) => {
      res[item.id] = item
      return res
    }, {})
  }

  const setItems = (items = []) => {
    const itemsMappedById = getItemsMappedById(items)

    innerItems.value = cloneDeep(itemsMappedById)
    initialItems = cloneDeep(itemsMappedById)
  }
  // END

  // START: Columns store
  const columnKeys = []

  const setColumn = (columnKey) => {
    set(checkedCount, columnKey, 0)
    initialCheckedCount[columnKey] = 0
    columnKeys.push(columnKey)
  }
  // END

  // START: Handle checkbox changes functions
  const changeCell = (value, rowKey, columnKey, item) => {
    innerItems.value[rowKey][columnKey] = value
    changeCheckedCountCell(value, columnKey)

    if (onChangeCell) onChangeCell(value, rowKey, columnKey, item)
  }

  const changeColumn = (value, columnKey) => {
    for (const id in innerItems.value) {
      innerItems.value[id][columnKey] = value
    }
    changeCheckedCountColumn(value, columnKey)

    if (onChangeColumn) onChangeColumn(value, columnKey)
  }
  // END

  // START: Store count of checked cells
  let initialCheckedCount = {}
  const checkedCount = reactive({})

  const setInitialCheckedCountCell = (value, columnKey) => {
    initialCheckedCount[columnKey] = initialCheckedCount[columnKey] + 1

    changeCheckedCountCell(value, columnKey)
  }

  const clearToInitialCheckedCount = () => {
    for (const key in initialCheckedCount) {
      checkedCount[key] = initialCheckedCount[key]
    }
  }

  const clearToCurrentCheckedCount = () => {
    initialCheckedCount = cloneDeep(checkedCount)
  }

  const changeCheckedCountCell = (value, columnKey) => {
    const currentCount = checkedCount[columnKey]
    const step = value ? 1 : -1
    checkedCount[columnKey] = currentCount + step
  }

  const changeCheckedCountColumn = (value, columnKey) => {
    checkedCount[columnKey] = value ? itemsCount.value : 0
  }
  // END

  const cancel = (setInitialData) => {
    if (setInitialData) {
      innerItems.value = cloneDeep(initialItems)
      clearToInitialCheckedCount()
    } else {
      initialItems = cloneDeep(innerItems.value)
      clearToCurrentCheckedCount()
    }
  }

  return {
    innerItems: readonly(innerItems),
    itemsCount,
    setItems,

    columnKeys,
    setColumn,

    changeCell,
    changeColumn,

    checkedCount,
    setInitialCheckedCountCell,

    editMode,
    toggleEditMode,

    cancel
  }
}

export default usePaymentCountriesStore
